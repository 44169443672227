type StorageLocations = 'search-history' | 'search-history';

export function localStorageAdd(location: StorageLocations, item: string) {
    const currentData = JSON.parse(localStorage.getItem(location) || '[]');
    try {
        currentData.push(item);
        localStorage.setItem(location, JSON.stringify(Array.from(currentData)));
        return currentData;
    } catch (error) {
        return [];
    }
}

export function localStorageRemove(location: StorageLocations, item: string) {
    const currentData = JSON.parse(localStorage.getItem(location) || '[]');
    const tempData = currentData.filter((e: string) => e.toLowerCase() !== item.toLowerCase());
    try {
        localStorage.setItem(location, JSON.stringify(Array.from(tempData)));
        // eslint-disable-next-line no-empty
    } catch (error) {
    }
    return tempData;
}

export function localStorageClear(location: StorageLocations) {
    try {
        localStorage.setItem(location, JSON.stringify(Array.from([])));
        // eslint-disable-next-line no-empty
    } catch (error) {
    }
}

export function localStorageGet(location: StorageLocations) {
    try {
        return JSON.parse(localStorage.getItem(location) || '[]').reverse();
    } catch (error) {
        return [];
    }
}
